.coming-soon-page {
    min-height: calc(100vh - 64px); /* Adjust based on your header height */
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    position: relative;
    overflow: hidden;
    padding: 2rem;
}

.coming-soon-content {
    max-width: 600px;
    width: 100%;
    text-align: center;
    z-index: 1;
}

.coming-soon-header {
    margin-bottom: 2rem;
}

.coming-soon-icon {
    color: var(--argus-primary);
    margin-bottom: 1.5rem;
}

.coming-soon-header h1 {
    font-size: 3rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 0.5rem;
}

.page-name {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    margin-bottom: 1rem;
}

.coming-soon-message {
    margin-bottom: 2.5rem;
}

.coming-soon-message p {
    font-size: 1.125rem;
    color: var(--argus-text-secondary);
    line-height: 1.7;
}

.notification-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.input-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--argus-gray-400);
}

.notification-form input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border: 2px solid var(--argus-gray-200);
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: all 0.2s ease;
    background: white;
}

.notification-form input:focus {
    outline: none;
    border-color: var(--argus-primary);
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.notify-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: var(--argus-primary);
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.notify-button:hover {
    background: var(--argus-primary-dark);
    transform: translateY(-1px);
}

.submission-message {
    margin-top: 1rem;
    color: var(--argus-primary);
    font-weight: 500;
    opacity: 0;
    transition: all 0.3s ease;
}

.submission-message.show {
    opacity: 1;
}

/* Decorative background elements */
.coming-soon-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.gradient-sphere {
    position: absolute;
    border-radius: 50%;
    filter: blur(60px);
    opacity: 0.1;
}

.gradient-sphere-1 {
    width: 400px;
    height: 400px;
    background: var(--argus-primary);
    top: -100px;
    right: -100px;
}

.gradient-sphere-2 {
    width: 300px;
    height: 300px;
    background: #00bfff;
    bottom: -50px;
    left: -50px;
}

.gradient-sphere-3 {
    width: 200px;
    height: 200px;
    background: var(--argus-primary);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
    .coming-soon-header h1 {
        font-size: 2.5rem;
    }
    
    .notification-form {
        padding: 0 1rem;
    }
    
    .gradient-sphere {
        filter: blur(40px);
    }
}