.faq-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    min-height: 100vh;
}

/* Hero Section */
.faq-hero {
    padding: 8rem 2rem 4rem;
    text-align: center;
    position: relative;
}

.faq-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.faq-hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
}

.hero-description {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 3rem;
}

/* Search Bar */
.search-container {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.search-icon {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--argus-text-secondary);
    pointer-events: none;
}

.search-input {
    width: 100%;
    padding: 1.25rem 1.25rem 1.25rem 4rem;
    border: 2px solid var(--argus-gray-200);
    border-radius: 1rem;
    font-size: 1.125rem;
    background: white;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: var(--argus-primary);
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}

/* Category Navigation */
.category-nav {
    position: sticky;
    top: 64px;
    background: white;
    border-bottom: 1px solid rgba(0, 123, 255, 0.1);
    z-index: 10;
    margin-top: 2rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.category-list {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

.category-list::-webkit-scrollbar {
    display: none;
}

.category-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 0.75rem;
    background: transparent;
    color: var(--argus-text-secondary);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.category-button svg {
    transition: all 0.2s ease;
}

.category-button:hover {
    color: var(--argus-primary);
    background: var(--argus-primary-light);
}

.category-button.active {
    color: var(--argus-primary);
    background: var(--argus-primary-light);
}

/* FAQ Content */
.faq-content {
    padding: 4rem 0;
}

.faq-category {
    margin-bottom: 4rem;
}

.faq-category:last-child {
    margin-bottom: 0;
}

.category-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.category-icon {
    width: 2rem;
    height: 2rem;
    color: var(--argus-primary);
}

.category-header h2 {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--argus-text-primary);
}

.questions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.question-item {
    background: white;
    border-radius: 1rem;
    border: 1px solid rgba(0, 123, 255, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
}

.question-item:hover {
    border-color: var(--argus-primary);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.1);
}

.question-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.question-header h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    padding-right: 2rem;
}

.expand-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--argus-primary);
    transition: transform 0.3s ease;
    flex-shrink: 0;
}

.expand-icon.rotated {
    transform: rotate(180deg);
}

.answer {
    padding: 0 1.5rem 1.5rem;
}

.answer p {
    color: var(--argus-text-secondary);
    line-height: 1.7;
}

/* Contact Section */
.faq-contact {
    padding: 4rem 2rem;
    background: white;
}

.contact-card {
    background: linear-gradient(to right, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.02));
    padding: 3rem;
    border-radius: 1rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

.contact-card h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.contact-card p {
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
}

.contact-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.primary-button,
.secondary-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease;
}

.primary-button {
    background: var(--argus-primary);
    color: white;
}

.primary-button:hover {
    background: var(--argus-primary-dark);
    transform: translateY(-1px);
}

.secondary-button {
    background: var(--argus-primary-light);
    color: var(--argus-primary);
}

.secondary-button:hover {
    background: rgba(0, 123, 255, 0.15);
    transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .faq-hero {
        padding: 6rem 1rem 3rem;
    }

    .faq-hero h1 {
        font-size: 2.5rem;
    }

    .hero-description {
        font-size: 1.125rem;
    }

    .container {
        padding: 0 1rem;
    }

    .category-list {
        padding: 0.75rem 0;
    }

    .category-button {
        padding: 0.625rem 1rem;
    }

    .contact-buttons {
        flex-direction: column;
    }

    .contact-card {
        padding: 2rem 1.5rem;
    }
}

@media (max-width: 480px) {
    .faq-hero h1 {
        font-size: 2rem;
    }

    .search-input {
        font-size: 1rem;
        padding: 1rem 1rem 1rem 3.5rem;
    }

    .category-header h2 {
        font-size: 1.5rem;
    }

    .question-header h3 {
        font-size: 1rem;
    }
}

/* Print Styles */
@media print {
    .search-container,
    .category-nav,
    .faq-contact {
        display: none;
    }

    .question-item {
        break-inside: avoid;
    }

    .answer {
        display: block !important;
        height: auto !important;
        opacity: 1 !important;
    }
}