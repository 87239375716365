.terms-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    min-height: 100vh;
}

/* Hero Section */
.terms-hero {
    padding: 8rem 2rem 4rem;
    text-align: center;
    position: relative;
}

.terms-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.terms-hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
}

.terms-hero p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.last-updated {
    font-size: 0.875rem;
    color: var(--argus-text-secondary);
}

/* Quick Nav */
.quick-nav {
    position: sticky;
    top: 64px;
    background: white;
    border-bottom: 1px solid rgba(0, 123, 255, 0.1);
    z-index: 10;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.quick-nav-content {
    display: flex;
    gap: 2rem;
    padding: 1rem 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.quick-nav-item {
    font-size: 0.875rem;
    color: var(--argus-text-secondary);
    text-decoration: none;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.quick-nav-item:hover {
    color: var(--argus-primary);
}

/* Content Sections */
.terms-content {
    padding: 4rem 0;
}

.terms-section {
    padding: 3rem 0;
    border-bottom: 1px solid rgba(0, 123, 255, 0.1);
}

.terms-section:last-child {
    border-bottom: none;
}

.section-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.section-icon {
    width: 2rem;
    height: 2rem;
    color: var(--argus-primary);
}

.section-header h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--argus-text-primary);
}

.section-content {
    color: var(--argus-text-secondary);
    line-height: 1.8;
}

.section-content p {
    margin-bottom: 1.5rem;
    white-space: pre-line;
}

.section-content p:last-child {
    margin-bottom: 0;
}

/* Contact Section */
.terms-contact {
    padding: 4rem 2rem;
    background: white;
}

.contact-card {
    background: linear-gradient(to right, rgba(0, 123, 255, 0.05), rgba(0, 123, 255, 0.02));
    padding: 3rem;
    border-radius: 1rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

.contact-card h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.contact-card p {
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
}

.contact-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: var(--argus-primary);
    color: white;
    border-radius: 0.5rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease;
}

.contact-button:hover {
    background: var(--argus-primary-dark);
    transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .terms-hero h1 {
        font-size: 2.5rem;
    }

    .terms-hero {
        padding: 6rem 1rem 3rem;
    }

    .container {
        padding: 0 1rem;
    }

    .section-header h2 {
        font-size: 1.75rem;
    }

    .contact-card {
        padding: 2rem 1rem;
    }
}

@media (max-width: 480px) {
    .terms-hero h1 {
        font-size: 2rem;
    }

    .quick-nav-content {
        gap: 1rem;
    }

    .section-content {
        font-size: 0.9375rem;
    }
}