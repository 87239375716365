.features-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    position: relative;
    overflow: hidden;
  }
  
  .features-hero {
    padding: 8rem 2rem 6rem;
    text-align: center;
    position: relative;
  }
  
  .features-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
  }
  
  .features-hero-title {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    color: var(--argus-text-primary);
  }
  
  .gradient-text {
    background: linear-gradient(45deg, var(--argus-primary) 0%, #00bfff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .features-hero-description {
    font-size: 1.25rem;
    max-width: 800px;
    margin: 0 auto;
    color: var(--argus-text-secondary);
    line-height: 1.8;
  }
  
  .feature-section {
    padding: 6rem 2rem;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .feature-section.expanded {
    padding-bottom: 8rem;
  }
  
  .feature-section-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 4rem;
    align-items: center;
  }
  
  @media (min-width: 768px) {
    .feature-section-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .feature-icon-wrapper {
    display: inline-flex;
    padding: 1rem;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .feature-icon-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.2) 0%, transparent 70%);
    pointer-events: none;
  }
  
  .feature-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--argus-primary);
    position: relative;
    z-index: 1;
  }
  
  .feature-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 1.5rem 0;
    color: var(--argus-text-primary);
  }
  
  .feature-description {
    font-size: 1.125rem;
    line-height: 1.8;
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
  }
  
  .feature-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .feature-list-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
  }
  
  .feature-list-item:hover {
    background: rgba(0, 123, 255, 0.05);
    transform: translateX(5px);
  }
  
  .feature-list-icon-wrapper {
    padding: 0.5rem;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .feature-list-icon {
    width: 1rem;
    height: 1rem;
    color: var(--argus-primary);
  }

  .feature-list-text{
    font-size: 1.125rem;
    color: var(--argus-text-secondary);
  }
  
  .feature-expand-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--argus-primary);
    background: none;
    border: none;
    padding: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
    font-weight: 500;
  }
  
  .expand-icon {
    transition: transform 0.3s ease;
  }
  
  .expand-icon.rotated {
    transform: rotate(90deg);
  }
  
  .feature-visual {
    position: relative;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background: rgba(0, 123, 255, 0.05);
    overflow: hidden;
  }
  
  .feature-visual-container {
    position: relative;
    z-index: 1;
  }
  
  .feature-visual-icon {
    width: 8rem;
    height: 8rem;
    color: var(--argus-primary);
    opacity: 0.8;
  }
  
  .feature-visual-gradient {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    background: conic-gradient(
      from 0deg at 50% 50%,
      rgba(0, 123, 255, 0.1) 0deg,
      transparent 60deg,
      rgba(0, 123, 255, 0.1) 120deg,
      transparent 180deg,
      rgba(0, 123, 255, 0.1) 240deg,
      transparent 300deg,
      rgba(0, 123, 255, 0.1) 360deg
    );
    animation: rotate 20s linear infinite;
  }
  
  .features-cta {
    padding: 8rem 2rem;
    text-align: center;
    position: relative;
  }
  
  .features-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
  }

  .features-cta-description{
    font-size: 2rem;
    max-width: 700px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 2rem;
    color: var(--argus-text-primary);
    line-height: 1.2;
  }
  
  .features-cta-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--argus-primary);
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-arrow {
    transition: transform 0.3s ease;
  }
  
  .features-cta-button:hover .cta-arrow {
    transform: translateX(5px);
  }
  
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @media (max-width: 768px) {
    .features-hero-title {
      font-size: 3rem;
    }
    
    .feature-section {
      padding: 4rem 1rem;
    }
    
    .feature-title {
      font-size: 2rem;
    }
}