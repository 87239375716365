.solutions-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    position: relative;
    overflow: hidden;
    min-height: 100vh;
  }
  
  .solutions-hero {
    padding: 8rem 2rem 6rem;
    text-align: center;
    position: relative;
  }
  
  .solutions-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
  }
  
  .solutions-title {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    color: var(--argus-text-primary);
  }
  
  .gradient-text {
    background: linear-gradient(45deg, var(--argus-primary) 0%, #00bfff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  
  .solutions-description {
    font-size: 1.25rem;
    max-width: 800px;
    margin: 0 auto;
    color: var(--argus-text-secondary);
    line-height: 1.8;
  }
  
  .industries-grid {
    max-width: 1200px;
    margin: 4rem auto;
    display: grid;
    gap: 2rem;
    padding: 0 2rem;
  }
  
  @media (min-width: 768px) {
    .industries-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .industry-card {
    background: var(--argus-bg-primary);
    border-radius: 1.5rem;
    padding: 2.5rem;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(0, 123, 255, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .industry-header {
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;
  }
  
  .industry-icon-wrapper {
    display: inline-flex;
    padding: 1rem;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .industry-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--argus-primary);
    position: relative;
    z-index: 1;
  }
  
  .industry-icon-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 150%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.2) 0%, transparent 70%);
    pointer-events: none;
  }
  
  .industry-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 0.5rem;
  }
  
  .industry-description {
    font-size: 1.125rem;
    line-height: 1.6;
    color: var(--argus-text-secondary);
  }
  
  .industry-benefits {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: hidden;
  }
  
  .benefit-item {
    background: rgba(0, 123, 255, 0.03);
    border-radius: 1rem;
    padding: 1.5rem;
  }
  
  .benefit-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.75rem;
  }
  
  .benefit-check {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--argus-primary);
  }
  
  .benefit-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    flex-grow: 1;
  }
  
  .benefit-metric {
    font-size: 1rem;
    font-weight: 600;
    color: var(--argus-primary);
    background: rgba(0, 123, 255, 0.1);
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
  }
  
  .benefit-description {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--argus-text-secondary);
  }
  
  .industry-expand-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--argus-primary);
    background: none;
    border: none;
    padding: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    margin-top: 0.5rem;
  }
  
  .expand-icon {
    transition: transform 0.3s ease;
  }
  
  .expand-icon.rotated {
    transform: rotate(90deg);
  }
  
  .solutions-cta {
    padding: 6rem 2rem;
    text-align: center;
    position: relative;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.05) 0%, transparent 70%);
  }
  
  .solutions-cta h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
  }
  
  .solutions-cta p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .solution-cta-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--argus-primary);
    color: white;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-arrow {
    transition: transform 0.3s ease;
  }
  
  .solution-cta-button:hover .cta-arrow {
    transform: translateX(5px);
  }
  
  @media (max-width: 768px) {
    .solutions-title {
      font-size: 3rem;
    }
    
    .industry-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .industry-icon-wrapper {
      margin-bottom: 1rem;
    }
  }