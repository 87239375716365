.contact-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    min-height: 100vh;
}

/* Hero Section */
.contact-hero {
    padding: 8rem 2rem 4rem;
    text-align: center;
    position: relative;
}

.contact-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.contact-hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
}

.contact-hero p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    max-width: 600px;
    margin: 0 auto;
}

/* Contact Methods */
.contact-methods {
    padding: 4rem 2rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.methods-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.method-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 123, 255, 0.1);
    transition: all 0.3s ease;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.method-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.method-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--argus-primary);
    margin-bottom: 1rem;
}

.method-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    margin-bottom: 0.5rem;
}

.method-card p {
    color: var(--argus-text-secondary);
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.method-action {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--argus-primary);
    font-weight: 500;
    margin-top: auto;
    transition: gap 0.3s ease;
}

.method-card:hover .method-action {
    gap: 0.75rem;
}

/* Contact Form Section */
.contact-form-section {
    padding: 4rem 2rem;
    background: white;
    border-radius: 2rem 2rem 0 0;
    position: relative;
}

.contact-form-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top, rgba(0, 123, 255, 0.03) 0%, transparent 70%);
    pointer-events: none;
}

.form-content {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.form-header {
    text-align: center;
    margin-bottom: 3rem;
}

.form-header h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.form-header p {
    color: var(--argus-text-secondary);
    font-size: 1.125rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group.full-width {
    grid-column: 1 / -1;
}

.form-group label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--argus-text-primary);
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 0.875rem;
    border: 2px solid var(--argus-gray-200);
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: all 0.2s ease;
    color: var(--argus-text-primary);
    background: white;
}

.form-group input:hover,
.form-group select:hover,
.form-group textarea:hover {
    border-color: var(--argus-gray-300);
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--argus-primary);
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.submit-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: var(--argus-primary);
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    align-self: center;
}

.submit-button:hover {
    background: var(--argus-primary-dark);
    transform: translateY(-1px);
}

.submission-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #10B981;
    font-weight: 500;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;
    margin-top: 1rem;
    padding: 1rem;
    background: rgba(16, 185, 129, 0.1);
    border-radius: 0.5rem;
}

.submission-message.show {
    opacity: 1;
}

/* Office Section */
.office-section {
    padding: 4rem 2rem;
}

.office-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 123, 255, 0.1);
    max-width: 400px;
    margin: 0 auto;
}

.office-card svg {
    color: var(--argus-primary);
    margin-bottom: 1rem;
}

.office-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.office-card p {
    color: var(--argus-text-secondary);
    line-height: 1.6;
    margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-hero h1 {
        font-size: 2.5rem;
    }

    .form-grid {
        grid-template-columns: 1fr;
    }

    .contact-hero {
        padding: 6rem 1rem 3rem;
    }

    .contact-methods,
    .contact-form-section,
    .office-section {
        padding: 3rem 1rem;
    }

    .method-card {
        padding: 1.5rem;
    }

    .contact-form {
        padding: 1.5rem;
    }

    .form-header h2 {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .contact-hero h1 {
        font-size: 2rem;
    }

    .contact-hero p {
        font-size: 1.125rem;
    }

    .submit-button {
        width: 100%;
        justify-content: center;
    }
}