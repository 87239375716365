.about-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    min-height: 100vh;
}

/* Hero Section */
.about-hero {
    padding: 8rem 2rem 4rem;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.about-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.about-hero h1 {
    font-size: 4rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.hero-description {
    font-size: 1.5rem;
    color: var(--argus-text-secondary);
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.gradient-text {
    background: linear-gradient(45deg, var(--argus-primary) 0%, #00bfff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

/* Mission Section */
.mission-section {
    padding: 6rem 0;
    background: white;
}

.mission-content {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 4rem;
    align-items: center;
}

.mission-text h2 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
}

.mission-text p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    line-height: 1.8;
    margin-bottom: 3rem;
}

.mission-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.stat-number {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--argus-primary);
}

.stat-label {
    font-size: 1rem;
    color: var(--argus-text-secondary);
}

.mission-visual {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--argus-primary-light);
    border-radius: 2rem;
    padding: 3rem;
    position: relative;
    overflow: hidden;
}

.mission-visual::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: conic-gradient(from 0deg at 50% 50%,
        rgba(0, 123, 255, 0.1) 0deg,
        transparent 60deg,
        rgba(0, 123, 255, 0.1) 120deg,
        transparent 180deg,
        rgba(0, 123, 255, 0.1) 240deg,
        transparent 300deg,
        rgba(0, 123, 255, 0.1) 360deg
    );
    animation: rotate 20s linear infinite;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.mission-icon {
    width: 12rem;
    height: 12rem;
    color: var(--argus-primary);
    opacity: 0.8;
    position: relative;
    z-index: 1;
}

/* Values Section */
.values-section {
    padding: 6rem 0;
    background: var(--argus-bg-secondary);
    position: relative;
    overflow: hidden;
}

.values-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top right, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.values-section h2 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--argus-text-primary);
}

.values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    position: relative;
    z-index: 1;
}

.value-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 123, 255, 0.1);
}

.value-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    border-color: var(--argus-primary);
}

.value-icon {
    width: 3rem;
    height: 3rem;
    color: var(--argus-primary);
    margin-bottom: 1.5rem;
}

.value-card h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.value-card p {
    color: var(--argus-text-secondary);
    line-height: 1.6;
}

/* Journey Section */
.journey-section {
    padding: 6rem 0;
    background: white;
    position: relative;
}

.journey-section h2 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 4rem;
    color: var(--argus-text-primary);
}

.timeline {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(to bottom,
        transparent,
        var(--argus-primary),
        transparent
    );
    transform: translateX(-50%);
}

.timeline-item {
    position: relative;
    margin-bottom: 4rem;
    width: calc(50% - 2rem);
}

.timeline-item:nth-child(odd) {
    margin-left: auto;
    padding-left: 2rem;
}

.timeline-item:nth-child(even) {
    margin-right: auto;
    padding-right: 2rem;
    text-align: right;
}

.timeline-marker {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: var(--argus-primary);
    border: 2px solid white;
    border-radius: 50%;
    box-shadow: 0 0 0 4px var(--argus-primary-light);
}

.timeline-item:nth-child(odd) .timeline-marker {
    left: -0.5rem;
    top: 1.5rem;
}

.timeline-item:nth-child(even) .timeline-marker {
    right: -0.5rem;
    top: 1.5rem;
}

.timeline-content {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 123, 255, 0.1);
}

.year {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--argus-primary);
    margin-bottom: 0.5rem;
    display: block;
}

.timeline-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.timeline-content p {
    color: var(--argus-text-secondary);
    line-height: 1.6;
}

/* Recognition Section */
.recognition-section {
    padding: 6rem 0;
    background: var(--argus-bg-secondary);
}

.recognition-section h2 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
    color: var(--argus-text-primary);
}

.awards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.award-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 123, 255, 0.1);
    transition: all 0.3s ease;
}

.award-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.award-icon {
    width: 3rem;
    height: 3rem;
    color: var(--argus-primary);
    margin-bottom: 1.5rem;
}

.award-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--argus-text-primary);
    margin-bottom: 0.5rem;
}

.award-card p {
    color: var(--argus-text-secondary);
}

/* CTA Section */
.about-cta {
    padding: 6rem 0;
    background: white;
    text-align: center;
}

.cta-content {
    max-width: 600px;
    margin: 0 auto;
}

.cta-content h2 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.cta-content p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.primary-button,
.secondary-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
}

.primary-button {
    background: var(--argus-primary);
    color: white;
}

.primary-button:hover {
    background: var(--argus-primary-dark);
    transform: translateY(-1px);
}

.secondary-button {
    background: var(--argus-primary-light);
    color: var(--argus-primary);
}

.secondary-button:hover {
    background: rgba(0, 123, 255, 0.15);
    transform: translateY(-1px);
}

/* Animations */
@keyframes rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .mission-content {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .mission-visual {
        max-width: 400px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .about-hero h1 {
        font-size: 3rem;
    }

    .hero-description {
        font-size: 1.25rem;
    }

    .timeline::before {
        left: 0;
    }

    .timeline-item {
        width: 100%;
        padding-left: 2rem !important;
        padding-right: 0 !important;
        text-align: left !important;
    }

    .timeline-marker {
        left: -0.5rem !important;
        right: auto !important;
    }

    .mission-stats {
        grid-template-columns: 1fr;
    }

    .cta-buttons {
        flex-direction: column;
    }

    .about-hero,
    .mission-section,
    .values-section,
    .journey-section,
    .recognition-section,
    .about-cta {
        padding: 4rem 0;
    }
}

@media (max-width: 480px) {
    .about-hero h1 {
        font-size: 2.5rem;
    }

    .container {
        padding: 0 1rem;
    }

    .mission-text h2,
    .values-section h2,
    .journey-section h2,
    .recognition-section h2,
    .cta-content h2 {
        font-size: 2rem;
    }
}