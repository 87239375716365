:root {
  --argus-primary: #007bff;
  --argus-primary-light: #e6f0ff;
  --argus-primary-dark: #0056b3;
  --argus-gray-800: #1f2937;
  --argus-gray-700: #374151;
  --argus-gray-500: #6b7280;
  --argus-gray-400: #9ca3af;
  --argus-gray-300: #d1d5db;
}

.main-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-layout-header {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 50;
}

.nav-link {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-bottom: 2px solid transparent;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 150ms ease-in-out;
}

.nav-link.active {
  border-color: var(--argus-primary);
  color: var(--argus-gray-800);
}

.nav-link:not(.active) {
  color: var(--argus-gray-500);
}

.nav-link:hover:not(.active) {
  border-color: var(--argus-gray-300);
  color: var(--argus-gray-700);
}

.sign-in-button {
  color: var(--argus-primary);
  background-color: var(--argus-primary-light);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 150ms ease-in-out;
}

.sign-in-button:hover {
  background-color: rgba(0, 123, 255, 0.15);
}

.demo-button {
  background-color: var(--argus-primary);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 150ms ease-in-out;
}

.demo-button:hover {
  background-color: var(--argus-primary-dark);
}

.main-layout-footer {
  background-color: var(--argus-gray-800);
  color: white;
  padding: 3rem 0;
}

.footer-link {
  color: var(--argus-gray-300);
  transition: color 150ms ease-in-out;
}

.footer-link:hover {
  color: white;
}

.social-link {
  color: var(--argus-gray-400);
  transition: color 150ms ease-in-out;
}

.social-link:hover {
  color: white;
}

.nav-items-container {
  display: flex;
  align-items: center;
  gap: 2rem;  /* Consistent spacing between all items */
  margin-left: 2rem;  /* Space after logo */
}

.features-dropdown-trigger {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-bottom: 2px solid transparent;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 150ms ease-in-out;
  color: var(--argus-gray-500);
  background: none;
  border: none;
  cursor: pointer;
  height: 100%;  /* Match height with other nav items */
}

.features-dropdown-trigger:hover {
  border-color: var(--argus-gray-300);
  color: var(--argus-gray-700);
}

/* Add this for when dropdown is open */
.features-dropdown-trigger.active {
  border-color: var(--argus-primary);
  color: var(--argus-gray-800);
}

.features-dropdown {
  position: absolute;
  top: 100%;
  left: -100px;
  width: 600px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 0.5rem;
  z-index: 50;
}

.features-dropdown::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 120px;
  width: 12px;
  height: 12px;
  background: white;
  transform: rotate(45deg);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.features-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.feature-dropdown-item {
  display: flex;
  align-items: start;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.feature-dropdown-item:hover {
  background: rgba(0, 123, 255, 0.05);
}

.feature-dropdown-icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(0, 123, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--argus-primary);
}

.feature-dropdown-content h3 {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--argus-text-primary);
  margin-bottom: 0.25rem;
}

.feature-dropdown-content p {
  font-size: 0.85rem;
  color: var(--argus-text-secondary);
  line-height: 1.4;
}

.features-dropdown-footer {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.features-dropdown-all {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--argus-primary);
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.features-dropdown-all:hover {
  gap: 0.75rem;
}

.rotate-270 {
  transform: rotate(-90deg);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .features-dropdown {
    position: static;
    width: 100%;
    margin-top: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .features-dropdown-grid {
    grid-template-columns: 1fr;
  }

  .features-dropdown::before {
    display: none;
  }
}