@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-color: #1a202c; /* Equivalent to bg-gray-900 */
  color: #ffffff; /* Equivalent to text-white */
}

:root {
  /* Main brand colors */
  --argus-primary: #007bff;
  --argus-primary-light: #e6f0ff;
  --argus-primary-dark: #0056b3;

  /* Gray scale */
  --argus-gray-50: #f8fafc;
  --argus-gray-100: #f1f5f9;
  --argus-gray-200: #e2e8f0;
  --argus-gray-300: #cbd5e1;
  --argus-gray-400: #94a3b8;
  --argus-gray-500: #64748b;
  --argus-gray-600: #475569;
  --argus-gray-700: #334155;
  --argus-gray-800: #1e293b;
  --argus-gray-900: #0f172a;

  /* Feature illustration colors */
  --argus-feature-bg: var(--argus-primary-light);
  --argus-feature-icon: var(--argus-primary);
  --argus-feature-icon-opacity: 0.2;

  /* Text colors */
  --argus-text-primary: var(--argus-gray-900);
  --argus-text-secondary: var(--argus-gray-600);
  --argus-text-light: var(--argus-gray-400);

  /* Background colors */
  --argus-bg-primary: white;
  --argus-bg-secondary: var(--argus-gray-50);
  --argus-bg-tertiary: var(--argus-gray-100);

  /* Border colors */
  --argus-border-light: var(--argus-gray-200);
  --argus-border-medium: var(--argus-gray-300);
}

/* Optional: Dark mode colors */
@media (prefers-color-scheme: dark) {
  :root {
    --argus-bg-primary: var(--argus-gray-900);
    --argus-bg-secondary: var(--argus-gray-800);
    --argus-bg-tertiary: var(--argus-gray-700);
    
    --argus-text-primary: white;
    --argus-text-secondary: var(--argus-gray-300);
    --argus-text-light: var(--argus-gray-400);
    
    --argus-border-light: var(--argus-gray-700);
    --argus-border-medium: var(--argus-gray-600);
  }
}

html {
    scroll-behavior: smooth;
  }