.feature-detail-page {
    background: linear-gradient(to bottom, var(--argus-bg-primary) 0%, var(--argus-bg-secondary) 100%);
    min-height: 100vh;
}

/* Hero Section */
.feature-detail-hero {
    padding: 8rem 2rem 6rem;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.feature-detail-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
    pointer-events: none;
}

.feature-detail-hero-content {
    max-width: 800px;
    margin: 0 auto;
}

.feature-detail-icon-wrapper {
    display: inline-flex;
    padding: 1.5rem;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 1rem;
    margin-bottom: 2rem;
}

.feature-detail-icon {
    width: 3rem;
    height: 3rem;
    color: var(--argus-primary);
}

.feature-detail-hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--argus-text-primary);
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.feature-detail-description {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
    line-height: 1.8;
}

/* Key Points Section */
.feature-detail-keypoints {
    padding: 6rem 2rem;
    background: var(--argus-bg-primary);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.keypoints-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.keypoint-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 123, 255, 0.1);
}

.keypoint-card h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--argus-text-primary);
    margin-bottom: 1rem;
}

.keypoint-card p {
    color: var(--argus-text-secondary);
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.benefits-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.benefits-list li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--argus-text-secondary);
}

.benefits-list li svg {
    color: var(--argus-primary);
    flex-shrink: 0;
}

/* Overview Section */
.feature-detail-overview {
    padding: 6rem 2rem;
    background: linear-gradient(to bottom, var(--argus-bg-primary), var(--argus-bg-secondary));
}

.overview-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
}

.overview-text h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: var(--argus-text-primary);
}

.overview-text p {
    font-size: 1.125rem;
    line-height: 1.8;
    color: var(--argus-text-secondary);
}

.overview-visual {
    display: flex;
    justify-content: center;
    align-items: center;
}

.overview-icon {
    padding: 3rem;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 2rem;
    color: var(--argus-primary);
}

.feature-detail-cta{
    display: flex;
    justify-content: center;
}

/* CTA Section */
.feature-detail-cta-section {
    padding: 6rem 2rem;
    text-align: center;
    background: radial-gradient(circle at center, rgba(0, 123, 255, 0.1) 0%, transparent 70%);
}

.cta-content h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--argus-text-primary);
}

.cta-content p {
    font-size: 1.25rem;
    color: var(--argus-text-secondary);
    margin-bottom: 2rem;
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.primary-button, .secondary-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
}

.primary-button {
    background: var(--argus-primary);
    color: white;
    margin-right: 10px;
}

.primary-button:hover {
    background: var(--argus-primary-dark);
}

.secondary-button {
    background: var(--argus-primary-light);
    color: var(--argus-primary);
}

.secondary-button:hover {
    background: rgba(0, 123, 255, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
    .feature-detail-hero h1 {
        font-size: 2.5rem;
    }
    
    .overview-content {
        grid-template-columns: 1fr;
    }
    
    .cta-buttons {
        flex-direction: column;
    }
}